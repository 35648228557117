<template>
  <div>
    <van-nav-bar
      :title="title"
      right-text="按钮"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <van-icon name="wap-home-o" size="22" />
      </template>
    </van-nav-bar>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Main",
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.push("/mini/home");
    },
    ...mapActions({
      setTitle: "setTitleFunc",
    }),
  },
  created() {
    if (this.$route.fullPath === "/mini/main/specialist") {
      this.setTitle("专家查询");
    } else if (this.$route.fullPath === "/mini/main/doctorDetail") {
      this.setTitle("专家详情");
    } else if (this.$route.fullPath === "/mini/main/schedule") {
      this.setTitle("会议日程");
    }
  },
  computed: {
    ...mapGetters({
      title: "getPageTitle",
    }),
  },
};
</script>

<style scoped>
.van-nav-bar {
  background-color: #c10000;
}
.van-nav-bar >>> .van-icon-arrow-left {
  font-size: 22px;
}
.van-nav-bar >>> .van-nav-bar__title  {
  color: #fff;
}
.van-nav-bar >>> .van-icon {
  color: #fff;
}
.van-nav-bar::after {
  border: 0 solid transparent;
}
</style>